import axios from 'axios';

export default {
  getTransformersWithPagination(pageNumber, pageSize, nameFilter, transformerTypeIdFilter, sortField, sortDesc) {
    return axios.get('/api/Transformers', {
      params: {
        pageNumber, 
        pageSize, 
        nameFilter, 
        transformerTypeIdFilter: transformerTypeIdFilter.length > 0 ? transformerTypeIdFilter.join('!;!') : '', 
        sortField, 
        sortDesc
      }
    });
  },
  getTransformerTypes() { 
    return axios.get('/api/Transformers/GetTransformerTypes'); 
  },
};

