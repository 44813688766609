<template>
  <div>
    <transformers-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <transformers-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import TransformersFilter from '@/views/Nsi/Transformers/TransformersFilter';
import TransformersTable from '@/views/Nsi/Transformers/TransformersTable';

export default {
  name: 'Transformers',
  components: { TransformersTable, TransformersFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        transformerTypeIdFilter: []
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
