<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="1">
      <el-row class="m-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <span class="mr-2">Наименование:</span>
          <remote-search-input
            v-model="filterModel.nameFilter"
            searchUrl="/api/transformers/SearchByName"
          />
        </el-col>
        <el-col :span="6" class="filter--container ml-2">
          <span class="mr-2">Тип:</span>
          <el-select multiple v-model="filterModel.transformerTypeIdFilter" size="small">
            <el-option
              v-for="item in transformerTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import transformersApi from '@/api/nsi/transformers';

export default {
  name: 'TransformersFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  async mounted() {
    await this.getTransformerTypes();
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: ['1'],
      transformerTypes: [],
    };
  },
  methods: {
    handleChange(val) {
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    },
    async getTransformerTypes() {
      this.loading = true;
      const res = await transformersApi.getTransformerTypes();
      if (res.data) {
        this.transformerTypes = res.data;
      }
      this.loading = false;
    },
  }
};
</script>

<style scoped>

</style>
