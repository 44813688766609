<template>
  <div>
    <div>
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div>
      <el-table
        v-loading="loading"
        style="width: 100%"
        :height="tableHeight"
        :data="transformers"
        :default-sort = "{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange">
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          min-width="40">
        </el-table-column>
        <el-table-column
          prop="transformerTypeName"
          label="Тип трансформатора"
          sortable="custom"
          min-width="40">
        </el-table-column>
        <el-table-column
          prop="ratedSecondaryCurrent"
          label="Номинальное вторичное значение"
          sortable="custom"
          min-width="20">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import transformersApi from '@/api/nsi/transformers';
import pagination from '@/components/Ui/Pagination';
import tableResize from '@/mixins/tableResize.js';

export default {
  name: 'TransformersTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [tableResize],
  components: { pagination },
  data() {
    return {
      loading: false,
      transformers: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getTransformers();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getTransformers();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getTransformers() {
      this.loading = true;
      const res = await transformersApi.getTransformersWithPagination(
          this.pageNumber,
          this.pageSize,
          this.filterModel.nameFilter,
          this.filterModel.transformerTypeIdFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        this.transformers = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getTransformers();
    },
    async handleCurrentChange() {
      await this.getTransformers();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getTransformers();
    }
  }
};
</script>

<style scoped>

</style>
